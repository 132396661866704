import BG from "src/Components/BG";
import Header from "src/Components/Header";
import Body from "src/Components/Body";
import LabelGroups from "src/Components/LabelGroups";
import Label from "src/Components/Label";
import Line from "src/Components/Line";
import Text from "src/Components/Text";
import ButtonGroups from "src/Components/ButtonGroups";
import Button from "src/Components/Button";
import Image from "src/Components/Image";
import Actions from "src/Components/Actions";

import "./index.css";

interface TemplateProps {
  headerText?: string;
  muted: boolean;
  toggleMuted: () => void;
  data: DataJourneyOneTemplate;
}

const Template = (props: TemplateProps) => {
  const { bgImage, labels = null, text, rightSideImage, rightSideComponent, audio = null } = props.data;
  const { next = null, back = null } = props.data;
  const { muted, toggleMuted } = props;

  const templateType = "journey-one-template";

  return (
    <BG bgImage={bgImage}>
      <Header headerText={props.headerText} />
      <Body>
        <div className="base-journey-one-template-container">
          <div className="base-journey-one-template-panel-left">
            <div style={{ flex: 1 }}>
              {labels && (
                <LabelGroups>
                  {labels.map((label: LabelProps, index: number) => (
                    <Label key={index} {...label} />
                  ))}
                </LabelGroups>
              )}
              <div className="base-journey-one-template-line-container">
                <Line />
              </div>
              <Text templateType={templateType} text={text} />
            </div>
            <ButtonGroups>
              {back !== null && <Button {...back} />}
              {next !== null && <Button {...next} />}
            </ButtonGroups>
          </div>
          {rightSideImage && <Image style={styles.panelRight} styleImage={styles.image} imagePath={rightSideImage} />}
          {rightSideComponent && rightSideComponent}
        </div>
      </Body>
      <Actions showSound={Boolean(audio)} muted={muted} toggleMuted={toggleMuted} />
      {audio}
    </BG>
  );
};

const styles = {
  panelRight: {
    flex: 1,
  },
  image: {
    position: "absolute" as React.CSSProperties["position"],
    top: 0,
    right: 0,
    objectFit: "contain" as React.CSSProperties["objectFit"],
    height: "100%",
  },
};

export default Template;
