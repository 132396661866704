import { useState, useEffect } from "react";

import "./index.css";

const Fullscreen = () => {
  const [isFullscreenValue, setIsFullscreenValue] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);

  const isFullscreen = () => {
    return !!(
      document.fullscreenElement ||
      //@ts-ignore
      document.webkitFullscreenElement ||
      //@ts-ignore
      document.mozFullScreenElement ||
      //@ts-ignore
      document.msFullscreenElement
    );
  };

  const openfullscreen = () => {
    //@ts-ignore
    if (document.getElementById("root").requestFullscreen) {
      //@ts-ignore
      document.getElementById("root").requestFullscreen();
      //@ts-ignore
    } else if (document.getElementById("root").mozRequestFullScreen) {
      //@ts-ignore
      document.getElementById("root").mozRequestFullScreen();
      //@ts-ignore
    } else if (document.getElementById("root").webkitRequestFullscreen) {
      //@ts-ignore
      document.getElementById("root").webkitRequestFullscreen();
      //@ts-ignore
    } else if (document.getElementById("root").webkitRequestFullScreen) {
      //@ts-ignore
      document.getElementById("root").webkitRequestFullScreen();
      //@ts-ignore
    } else if (document.getElementById("root").msRequestFullscreen) {
      //@ts-ignore
      document.getElementById("root").msRequestFullscreen();
    }
  };

  const closefullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
      //@ts-ignore
    } else if (document.mozCancelFullScreen) {
      //@ts-ignore
      document.mozCancelFullScreen();
      //@ts-ignore
    } else if (document.webkitExitFullscreen) {
      //@ts-ignore
      document.webkitExitFullscreen();
      //@ts-ignore
    } else if (document.webkitExitFullScreen) {
      //@ts-ignore
      document.webkitExitFullScreen();
      //@ts-ignore
    } else if (document.msExitFullscreen) {
      //@ts-ignore
      document.msExitFullscreen();
    }
  };

  const toggleFullScreen = () => {
    if (isFullscreenValue) closefullscreen();
    else openfullscreen();
    setIsFullscreenValue(!isFullscreenValue);
  };

  useEffect(() => {
    setIsFullscreenValue(isFullscreen());
  }, []);

  useEffect(() => {
    //@ts-ignore
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    //@ts-ignore
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) setShow(!/Safari/.test(userAgent));
    else setShow(true);
  }, []);

  return (
    <>
      {show && (
        <div className="base-fullscreen" onClick={toggleFullScreen}>
          {isFullscreenValue ? (
            <img src={require(`./Assets/Icons/white/exit-fullscreen.png`)} alt="" style={{ padding: "1rem", width: "3rem" }} />
          ) : (
            <img src={require(`./Assets/Icons/white/fullscreen.png`)} alt="" style={{ padding: "1rem", width: "3rem" }} />
          )}
        </div>
      )}
    </>
  );
};

export default Fullscreen;
