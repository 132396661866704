import Icon from "src/Components/Icon";
import { useTheme } from "src/Components/ThemeContext";

import "./index.css";
import { useEffect, useState } from "react";

const Button = (props: ButtonProps) => {
  const theme = useTheme();

  const { startIcon = null, endIcon = null, label = null } = props;
  const { onClick = null, disabled = false } = props;
  const { style = {} } = props;
  const { type = "next" } = props;

  const [disabledStyle, setDisabledStyle] = useState<React.CSSProperties>({});
  const [styleButton, setStyleButton] = useState<React.CSSProperties>({});

  const handleOnClick = () => {
    if (!disabled && onClick !== null) onClick();
  };

  const colorsIcons = { next: "black", back: "white" };

  useEffect(() => {
    if (disabled && theme && theme.Button && theme.Button[type]?.disabled) {
      setDisabledStyle(theme.Button[type]?.disabled ?? {});
    } else {
      setDisabledStyle({});
    }
  }, [disabled]);

  useEffect(() => {
    console.log("theme", theme);

    const styleButtonBase = { ...(theme && theme.Button && theme.Button.base ? theme.Button.base : {}) };
    const styleButtonType = { ...(theme && theme.Button && theme.Button[type] ? theme.Button[type] : {}) };

    setStyleButton({
      ...styleButtonBase,
      ...{ ...styleButtonType, disabled: undefined },
      ...style,
    });
  }, [theme]);

  return (
    <div
      onClick={handleOnClick}
      className={`base-button ${onClick !== null ? "base-button-clickable" : ""}`}
      style={{ ...styleButton, ...disabledStyle }}
    >
      {startIcon !== null && typeof startIcon === "string" && (
        <Icon style={{ marginRight: label ? "1rem" : 0 }} icon={startIcon} color={colorsIcons[type]} />
      )}

      {label}
      {endIcon !== null && typeof endIcon === "string" && (
        <Icon style={{ marginLeft: label ? "1rem" : 0 }} icon={endIcon} color={colorsIcons[type]} />
      )}
    </div>
  );
};

export default Button;
