import Config from "src/Constants/Config";

import setCurrentPage from "src/Utils/setCurrentPage";

import { Animation, Audios } from "@yamildiego/presentation-kit";

import store from "src/Reducers/store";
import { useSelector } from "react-redux";

const PageId = 221;
const PreviousPageId = PageId - 1;
const NextPageId = PageId + 1;

const DATA_JOURNEY_ONE = () => {
  const path = "/images/balance_rollover/phone_21/phone_";
  const DATA_IMAGES = [
    { image: `${Config.BASE_URL}${path}0.png`, effect: "animate-phone", imageStyle: { width: "67.5rem", right: 0, top: 0 } },
    { image: `${Config.BASE_URL}${path}1.png`, imageStyle: { width: "67.5rem", right: 0, top: 0 } },
    { image: `${Config.BASE_URL}${path}2.png`, imageStyle: { right: 0, top: 0, width: "67.5rem" } },
  ];

  const times = [100, 300, 600];

  const currentPage = useSelector(() => store.getState().appReducer.currentPage);
  const muted = useSelector(() => store.getState().appReducer.muted);
  const audioSrc = `${Config.BASE_URL}/audios/Interface Sound - When selecting options.mp3`;

  return {
    bgImage: `${Config.BASE_URL}/images/balance_rollover/bg_20.png`,
    labels: [
      {
        icon: `${Config.BASE_URL}/images/icons/clock.png`,
        text: "5:45 PM",
      },
      {
        icon: `${Config.BASE_URL}/images/icons/location.png`,
        text: "Fed Square",
      },
    ],
    text: "On his way home, James\nreceives a message from PCXSuper.",
    back: { label: "Go Back", type: "back", startIcon: "arrow-left", onClick: () => setCurrentPage(PreviousPageId) },
    next: { label: "Continue", type: "next", endIcon: "arrow-right", onClick: () => setCurrentPage(NextPageId) },
    rightSideComponent: <Animation times={times} pageNumber={PageId} currentPage={currentPage} images={DATA_IMAGES} />,
    audio: (
      <Audios
        muted={muted}
        pageNumber={PageId}
        currentPage={currentPage}
        audios={[
          { src: audioSrc, timer: 0 },
          { src: audioSrc, timer: 600 },
        ]}
      />
    ),
  };
};

export default DATA_JOURNEY_ONE;
