import Config from "src/Constants/Config";

import setCurrentPage from "src/Utils/setCurrentPage";

import store from "src/Reducers/store";
import { useSelector } from "react-redux";

import { Audios } from "@yamildiego/presentation-kit";

const PageId = 201;
const PreviousPageId = 1;
const NextPageId = PageId + 1;

const DATA_INTRO = () => {
  const currentPage = useSelector(() => store.getState().appReducer.currentPage);
  const muted = useSelector(() => store.getState().appReducer.muted);
  const audioSrc = `${Config.BASE_URL}/audios/balance_rollover/PRCX_18_Balance - ${PageId}.mp3`;

  return {
    bgImage: `${Config.BASE_URL}/images/balance_rollover/bg_01.png`,
    title: "Introduction",
    text: `It's been a year since James started working\nat Specter as a senior consultant. As a part\nof his induction, Specter helped James\ncreate an account with PCXSuper.`,
    rightSideImage: { image: `${Config.BASE_URL}/images/balance_rollover/character_01.png`, style: { width: "33rem", top: "-15.5rem" } },
    back: { label: "Go Back", type: "back", startIcon: "arrow-left", onClick: () => setCurrentPage(PreviousPageId) },
    next: { label: "Continue", type: "next", endIcon: "arrow-right", onClick: () => setCurrentPage(NextPageId) },
    audio: <Audios muted={muted} pageNumber={PageId} currentPage={currentPage} audios={[{ src: audioSrc, timer: 0 }]} />,
  };
};

export default DATA_INTRO;
