import { TemplateType } from "@yamildiego/presentation-kit";

import DATA_INTRO from "./01DATA_INTRO";
import DATA_JOURNEY_ONE_02 from "./02DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_03 from "./03DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_04 from "./04DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_05 from "./05DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_06 from "./06DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_07 from "./07DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_08 from "./08DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_09 from "./09DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_10 from "./10DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_11 from "./11DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_12 from "./12DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_13 from "./13DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_14 from "./14DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_15 from "./15DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_16 from "./16DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_17 from "./17DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_18 from "./18DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_19 from "./19DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_20 from "./20DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_21 from "./21DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_22 from "./22DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_23 from "./23DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_24 from "./24DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_25 from "./25DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_26 from "./26DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_27 from "./27DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_28 from "./28DATA_JOURNEY_ONE";
import DATA_CHAT_29 from "./29DATA_CHAT";
import DATA_CHAT_30 from "./30DATA_CHAT";
import DATA_CHAT_31 from "./31DATA_CHAT";
import DATA_JOURNEY_ONE_32 from "./32DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_33 from "./33DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_34 from "./34DATA_JOURNEY_ONE";

const DEMO_WORKFLOW = [
  { page: 201, type: "intro-template" as TemplateType, dataTemplate: DATA_INTRO },
  { page: 202, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_02 },
  { page: 203, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_03 },
  { page: 204, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_04 },
  { page: 205, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_05 },
  { page: 206, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_06 },
  { page: 207, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_07 },
  { page: 208, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_08 },
  { page: 209, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_09 },
  { page: 210, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_10 },
  { page: 211, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_11 },
  { page: 212, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_12 },
  { page: 213, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_13 },
  { page: 214, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_14 },
  { page: 215, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_15 },
  { page: 216, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_16 },
  { page: 217, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_17 },
  { page: 218, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_18 },
  { page: 219, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_19 },
  { page: 220, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_20 },
  { page: 221, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_21 },
  { page: 222, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_22 },
  { page: 223, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_23 },
  { page: 224, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_24 },
  { page: 225, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_25 },
  { page: 226, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_26 },
  { page: 227, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_27 },
  { page: 228, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_28 },
  { page: 229, type: "chat-template" as TemplateType, dataTemplate: DATA_CHAT_29 },
  { page: 230, type: "chat-template" as TemplateType, dataTemplate: DATA_CHAT_30 },
  { page: 231, type: "chat-template" as TemplateType, dataTemplate: DATA_CHAT_31 },
  { page: 232, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_32 },
  { page: 233, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_33 },
  { page: 234, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_34 },
];

export default DEMO_WORKFLOW;
