import setCurrentPage from "src/Utils/setCurrentPage";
import { Button, ButtonGroups } from "@yamildiego/presentation-kit";

const PageId = 0;
const NextPageId = PageId + 1;

const Home = () => {
  const next = { label: "Enter", type: "next", endIcon: "arrow-right", onClick: () => setCurrentPage(NextPageId) };

  const formatText = (text: string) => ({ __html: text.replace(/\n/g, "<br>") });

  const title = "SuperAnnuation\nAGENT & Member Experience";
  const subtitle =
    "Delve into diverse experiences encountered in the superannuation industry\nthrough three unique and immersive real-world scenarios.";
  return (
    <div style={styles.container}>
      <img style={styles.logo} src="./images/intro/logo.png" alt="" />
      <div style={styles.title} dangerouslySetInnerHTML={formatText(title)} />
      <div style={styles.subtitle} dangerouslySetInnerHTML={formatText(subtitle)} />
      <ButtonGroups>{next !== null && <Button {...next} />}</ButtonGroups>
    </div>
  );
};

const styles = {
  container: {
    width: "100%",
    display: "flex" as React.CSSProperties["display"],
    flexDirection: "column" as React.CSSProperties["flexDirection"],
    justifyContent: "center" as React.CSSProperties["justifyContent"],
    alignItems: "center" as React.CSSProperties["alignItems"],
    paddingTop: "10rem",
  },
  logo: {
    width: "23.4375rem",
  },
  title: {
    margin: "8.1875rem 0 0 0",
    width: "111.4375rem",
    color: "#FFF",
    textAlign: "center" as React.CSSProperties["textAlign"],
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Arial",
    fontSize: "5.625rem",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "120%",
    letterSpacing: "0.84375rem",
    textTransform: "uppercase" as React.CSSProperties["textTransform"],
  },
  subtitle: {
    color: "#FFF",
    textAlign: "center" as React.CSSProperties["textAlign"],
    fontFamily: "Arial",
    fontSize: "2rem",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "100%",
    width: "70.6875rem",
    marginTop: "1.5rem",
    marginBottom: "8rem",
  },
};

export default Home;
