import "./index.css";

import Fullscreen from "../Fullscreen";
import Sound from "../Sound";

interface IActions {
  showSound: boolean;
  muted: boolean;
  toggleMuted: () => void;
}

const Actions = (props: IActions) => {
  return (
    <div className="base-actions">
      <Sound show={props.showSound} muted={props.muted} toggleMuted={props.toggleMuted} />
      <Fullscreen />
    </div>
  );
};

export default Actions;
