import Config from "src/Constants/Config";

import setCurrentPage from "src/Utils/setCurrentPage";

import { Audios, Animation2 } from "@yamildiego/presentation-kit";

import store from "src/Reducers/store";
import { useSelector } from "react-redux";

const PageId = 304;
const PreviousPageId = PageId - 1;
const NextPageId = PageId + 1;

const DATA_CHAT = () => {
  const path = "/images/employee_onboarding/chat_04.png";

  const DATA_IMAGES = [{ image: `${Config.BASE_URL}${path}`, imageStyle: { position: "static", width: "100%" } }];

  const currentPage = useSelector(() => store.getState().appReducer.currentPage);
  const muted = useSelector(() => store.getState().appReducer.muted);
  const audioSrc = `${Config.BASE_URL}/audios/employee_onboarding/PRCX_18_Employee Onboarding - ${PageId}.mp3`;

  return {
    bgImage: `${Config.BASE_URL}/images/employee_onboarding/bg_3.png`,
    back: { type: "next", startIcon: "arrow-left", onClick: () => setCurrentPage(PreviousPageId) },
    next: { type: "next", endIcon: "arrow-right", onClick: () => setCurrentPage(NextPageId) },
    chatImages: <Animation2 step={0} pageNumber={PageId} currentPage={currentPage} images={DATA_IMAGES} />,
    audio: <Audios muted={muted} pageNumber={PageId} currentPage={currentPage} audios={[{ src: audioSrc, timer: 0 }]} />,
  };
};

export default DATA_CHAT;
