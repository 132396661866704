import { useEffect, useRef, useState } from "react";
import Image from "src/Components/Image";
import "./index.css";
import { CSS } from "@react-spring/web";

interface AnimationProps {
  images: { image: string; imageStyle: React.CSSProperties | {}; effect?: "animate-up" | "animate-opacity" | "animate-phone" }[];
  pageNumber: number;
  currentPage: number;
  times?: number[];
  step: number;
  conversationStyle?: React.CSSProperties;
}

const Animation2 = ({ images, times, pageNumber, conversationStyle = { zIndex: 1 }, currentPage, step }: AnimationProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const bubbles = containerRef.current?.querySelectorAll(".base-animation-message-bubble") || [];

  const [adjustedTimes, setAdjustedTimes] = useState<number[]>([]);

  useEffect(() => {
    const adjustTimes = () => {
      if (!times || times.length < images.length) {
        const newTimes = times ? [...times] : [0];
        while (newTimes.length < images.length) newTimes.push(900);
        return newTimes;
      } else return times;
    };

    setAdjustedTimes(adjustTimes());
  }, [images, times]);

  useEffect(() => {
    bubbles.forEach((bubble, index) => {
      if (index > step) bubble.classList.remove(images[index].effect ? (images[index].effect as string) : "animate-up");
    });

    // if (pageNumber === currentPage) startEffect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, currentPage, step]);

  useEffect(() => {
    if (bubbles && bubbles.length > 0) startEffect(step);
  }, [bubbles, step]);

  const startEffect = (step: number) => {
    setTimeout(() => {
      bubbles[step].classList.add(images[step].effect ? (images[step].effect as string) : "animate-up");
    }, 900);

    if (containerRef.current) containerRef.current.scrollTop = containerRef.current.scrollHeight;
  };

  return (
    <div className="base-animation" style={conversationStyle} ref={containerRef}>
      <div style={{ position: "relative", overflow: "hidden", width: "100%", height: "100%" }}>
        {images.map((item, index) => (
          <Image
            className={"base-animation-message-bubble"}
            key={index}
            styleImage={{ ...styles.img, ...item.imageStyle }}
            imagePath={item.image}
          />
        ))}
      </div>
    </div>
  );
};

const styles = {
  img: {
    width: "100%",
    height: "100%",
  },
};

export default Animation2;
