interface ImageProps {
  imagePath: string;
  style?: React.CSSProperties;
  styleImage?: React.CSSProperties;
  className?: string;
}

const Image = ({ imagePath, style, styleImage, className }: ImageProps) => (
  <div className={className} style={style}>
    <img style={styleImage} src={`${imagePath}`} alt="" />
  </div>
);

export default Image;
