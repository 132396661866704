import Config from "src/Constants/Config";

import setCurrentPage from "src/Utils/setCurrentPage";

import store from "src/Reducers/store";
import { useSelector } from "react-redux";

import { Audios, Animation } from "@yamildiego/presentation-kit";

const PageId = 215;
const PreviousPageId = PageId - 1;
const NextPageId = PageId + 1;

const DATA_JOURNEY_ONE = () => {
  const path = "/images/balance_rollover/win_15";
  const DATA_IMAGES = [
    { image: `${Config.BASE_URL}${path}.png`, effect: "animate-opacity", imageStyle: { right: 0, top: 0, width: "67.5rem" } },
  ];

  const currentPage = useSelector(() => store.getState().appReducer.currentPage);
  const muted = useSelector(() => store.getState().appReducer.muted);
  const audioSrc = `${Config.BASE_URL}/audios/balance_rollover/PRCX_18_Balance - ${PageId}.mp3`;

  return {
    bgImage: `${Config.BASE_URL}/images/balance_rollover/bg_03.png`,
    labels: [
      {
        icon: `${Config.BASE_URL}/images/icons/clock.png`,
        text: "4:15 PM",
      },
      {
        icon: `${Config.BASE_URL}/images/icons/location.png`,
        text: "Office",
      },
    ],
    text: "All information in the articles\nprovided are vetted and scheduled\nfor regular review with compliance.",
    back: { label: "Go Back", type: "back", startIcon: "arrow-left", onClick: () => setCurrentPage(PreviousPageId) },
    next: { label: "Continue", type: "next", endIcon: "arrow-right", onClick: () => setCurrentPage(NextPageId) },
    rightSideComponent: <Animation pageNumber={PageId} currentPage={currentPage} images={DATA_IMAGES} />,
    audio: <Audios muted={muted} pageNumber={PageId} currentPage={currentPage} audios={[{ src: audioSrc, timer: 0 }]} />,
  };
};

export default DATA_JOURNEY_ONE;
