interface BodyProps {
  children: React.ReactNode;
}

const Body = (props: BodyProps) => {
  return <div style={styles.container}>{props.children}</div>;
};

const styles = {
  container: {
    flex: 1,
    paddingLeft: "6.125rem",
    paddingRight: "6.5625rem",
    paddingBottom: "9.9375rem",
  },
};

export default Body;
