import Config from "src/Constants/Config";

import setCurrentPage from "src/Utils/setCurrentPage";

import { Animation } from "@yamildiego/presentation-kit";

import store from "src/Reducers/store";
import { useSelector } from "react-redux";

import { Audios } from "@yamildiego/presentation-kit";

const PageId = 103;
const PreviousPageId = PageId - 1;
const NextPageId = PageId + 1;

const DATA_JOURNEY_ONE = () => {
  const path = "/images/death_claim/phone_03/phone_";
  const DATA_IMAGES = [
    { image: `${Config.BASE_URL}${path}1.png`, effect: "animate-phone", imageStyle: { width: "72.375rem", right: 0, top: 0 } },
    { image: `${Config.BASE_URL}${path}2.png`, imageStyle: { right: 0, top: 0, width: "72.375rem" } },
    { image: `${Config.BASE_URL}${path}3.png`, imageStyle: { right: 0, top: 0, width: "72.375rem" } },
  ];

  const times = [100, 600, 4000];

  const currentPage = useSelector(() => store.getState().appReducer.currentPage);
  const muted = useSelector(() => store.getState().appReducer.muted);
  const audioSrc = `${Config.BASE_URL}/audios/death_claim/PRCX_18_Death Claim - ${PageId}.mp3`;

  return {
    bgImage: `${Config.BASE_URL}/images/death_claim/bg_opacity_1.png`,
    labels: [
      {
        icon: `${Config.BASE_URL}/images/icons/clock.png`,
        text: "8:05 AM",
      },
      {
        icon: `${Config.BASE_URL}/images/icons/location.png`,
        text: "Royal Botanical Garden",
      },
      {
        icon: `${Config.BASE_URL}/images/icons/oration.png`,
        text: "Oration",
        style: { backgroundColor: "#c3834e" },
      },
    ],
    text: "Sonya calls PCXSuper and is\ngreeted by Oration - The Probe CX\nConversation AI IVR System.",
    back: { label: "Go Back", type: "back", startIcon: "arrow-left", onClick: () => setCurrentPage(PreviousPageId) },
    next: { label: "Continue", type: "next", endIcon: "arrow-right", onClick: () => setCurrentPage(NextPageId) },
    rightSideComponent: <Animation times={times} pageNumber={PageId} currentPage={currentPage} images={DATA_IMAGES} />,
    audio: <Audios muted={muted} pageNumber={PageId} currentPage={currentPage} audios={[{ src: audioSrc, timer: 0 }]} />,
  };
};

export default DATA_JOURNEY_ONE;
