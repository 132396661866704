import React, { useEffect, useState } from "react";
import ThemeContext from "./ThemeContext";
import DataContext from "./DataContext";

interface ThemeProviderProps {
  children: React.ReactNode;
  themeStyles: KitTheme;
  globalData?: GlobalData;
  isResponsive?: boolean;
}

const ThemeProvider = (props: ThemeProviderProps) => {
  const { children, themeStyles } = props;
  const { isResponsive = false } = props;

  const [theme, setTheme] = useState<KitTheme>({});

  useEffect(() => {
    if (isResponsive) {
      //TODO: Implement responsive conversion
    } else {
      setTheme(themeStyles);
    }
  }, [isResponsive, themeStyles]);

  return (
    <ThemeContext.Provider value={theme}>
      <DataContext.Provider value={props.globalData ? props.globalData : {}}>{children}</DataContext.Provider>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
