import { divide } from "lodash";
import React, { useState, useEffect } from "react";
import Container from "./Container";

interface ScreenOrientationProps {
  children?: React.ReactNode;
}

const ScreenOrientation = (props: ScreenOrientationProps) => {
  const [orientation, setOrientation] = useState(window.innerWidth > window.innerHeight ? "landscape" : "portrait");

  useEffect(() => {
    const handleOrientationChange = () => {
      setOrientation(window.innerWidth > window.innerHeight ? "landscape" : "portrait");
      document.body.style.backgroundColor = window.innerWidth > window.innerHeight ? "#111" : "white";
    };

    window.addEventListener("resize", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  return (
    <div>
      {orientation === "landscape" && props.children}
      {orientation === "portrait" && (
        <div style={styles.container}>
          <h1 style={styles.title}>Please</h1>
          <h1 style={styles.title2}>Rotate your phone</h1>
          <img style={{ width: "90%", margin: "5%" }} src={require(`./Assets/Images/rotate.png`)} alt="" />
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column" as React.CSSProperties["flexDirection"],
    justifyContent: "center" as React.CSSProperties["justifyContent"],
    height: "85vh",
  },
  title: {
    marginBottom: "0",
    textAlign: "center" as React.CSSProperties["textAlign"],
    fontSize: "30px",
    color: "#333",
    textTransform: "uppercase" as React.CSSProperties["textTransform"],
  },
  title2: {
    marginTop: "0",
    textAlign: "center" as React.CSSProperties["textAlign"],
    fontSize: "30px",
    color: "#333",
    textTransform: "uppercase" as React.CSSProperties["textTransform"],
  },
};

export default ScreenOrientation;
