import Line from "src/Components/Line";

import "./index.css";

const CardIntro = (props: CardIntroProps) => {
  const { cardStyle = {}, title, text, imageRight = null, imageStyle = {} } = props;

  const formatText = (text: string) => ({ __html: text.replace(/\n/g, "<br>") });

  return (
    <div className="base-card-intro" style={cardStyle}>
      <div className="base-card-intro-title" dangerouslySetInnerHTML={formatText(title)} />
      <div className="base-card-intro-line-container">
        <Line />
      </div>
      <div className="base-card-intro-text" dangerouslySetInnerHTML={formatText(text)} />
      {imageRight !== null && <img className="base-card-intro-image" style={imageStyle} src={imageRight} alt="" />}
    </div>
  );
};

export default CardIntro;
