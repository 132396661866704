import Config from "src/Constants/Config";

import Characters from "src/Pages/Characters";

const DATA_INTRO = {
  bgImage: `${Config.BASE_URL}/images/intro/choose_chatacter.png`,
  showHeader: true,
  children: <Characters />,
};

export default DATA_INTRO;
