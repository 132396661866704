import Config from "src/Constants/Config";

import setCurrentPage from "src/Utils/setCurrentPage";

import { Audios, Animation } from "@yamildiego/presentation-kit";

import store from "src/Reducers/store";
import { useSelector } from "react-redux";

const DATA_CHAT = () => {
  const PageId = 109;
  const PreviousPageId = PageId - 1;
  const NextPageId = PageId + 1;

  const path = "/images/death_claim/chat_09/chat_";

  const DATA_IMAGES = [
    { image: `${Config.BASE_URL}${path}0.png`, imageStyle: { position: "static", width: "100%" } },
    { image: `${Config.BASE_URL}${path}1.png`, imageStyle: { position: "static", width: "100%" } },
    { image: `${Config.BASE_URL}${path}2.png`, imageStyle: { position: "static", width: "100%" } },
    { image: `${Config.BASE_URL}${path}3.png`, imageStyle: { position: "static", width: "100%" } },
  ];

  const pathAudios = `${Config.BASE_URL}/audios/death_claim/`;

  const AUDIOS = [
    { src: `${pathAudios}PRCX_18_Death Claim - Scene 9 - Franky 1 - v1.mp3`, timer: 100 },
    { src: `${pathAudios}PRCX_18_Death Claim - Scene 9 - Senior Lady 1 - v1.mp3`, timer: 8000 },
    { src: `${pathAudios}PRCX_18_Death Claim - Scene 9 - Franky 2 - v1.mp3`, timer: 10800 },
    { src: `${pathAudios}PRCX_18_Death Claim - Scene 9 - Senior Lady 2 - v1.mp3`, timer: 17500 },
  ];

  const times = [600, 8000, 2000, 7000];

  const currentPage = useSelector(() => store.getState().appReducer.currentPage);
  const muted = useSelector(() => store.getState().appReducer.muted);

  return {
    bgImage: `${Config.BASE_URL}/images/death_claim/bg_opacity_09.png`,
    rightSideImage: {
      image: `${Config.BASE_URL}/images/death_claim/man_chat.png`,
      style: { width: "52.6875rem", display: "flex", height: "100%" },
    },
    leftSideImage: {
      image: `${Config.BASE_URL}/images/death_claim/OlderWoman_chat.png`,
      style: { width: "25rem", display: "flex", height: "100%" },
    },
    back: { type: "back", startIcon: "arrow-left", onClick: () => setCurrentPage(PreviousPageId) },
    next: { type: "next", endIcon: "arrow-right", onClick: () => setCurrentPage(NextPageId) },
    chatImages: <Animation times={times} pageNumber={PageId} currentPage={currentPage} images={DATA_IMAGES} />,
    audio: <Audios muted={muted} pageNumber={PageId} currentPage={currentPage} audios={AUDIOS} />,
  };
};

export default DATA_CHAT;
