import BG from "src/Components/BG";
import Header from "src/Components/Header";
import Body from "src/Components/Body";
import Actions from "src/Components/Actions";

import "./index.css";

interface IntroTemplateProps {
  headerText?: string;
  data: DataBaseTemplate;
}

const IntroTemplate = (props: IntroTemplateProps) => {
  const { bgImage, showHeader = false, children = <></> } = props.data;

  const templateType = "base-template";

  return (
    <BG bgImage={bgImage}>
      {showHeader && <Header headerText={props.headerText} />}
      <Body>{children}</Body>
      <Actions showSound={false} muted={false} toggleMuted={() => {}} />
    </BG>
  );
};

export default IntroTemplate;
