import BG from "src/Components/BG";
import Header from "src/Components/Header";
import Body from "src/Components/Body";
import CardIntro from "src/Components/CardIntro";
import Button from "src/Components/Button";
import ButtonGroups from "src/Components/ButtonGroups";
import Actions from "src/Components/Actions";

import "./index.css";

interface IntroTemplateProps {
  headerText?: string;
  muted: boolean;
  toggleMuted: () => void;
  data: DataIntroTemplate;
}

const IntroTemplate = (props: IntroTemplateProps) => {
  const { bgImage, title, text, back = null, next = null, rightSideImage = null, audio = null } = props.data;
  const { muted, toggleMuted } = props;

  const templateType = "intro-template";

  return (
    <BG bgImage={bgImage}>
      <Header headerText={props.headerText} />
      <Body>
        <div className="base-intro-template-container">
          <div className="base-intro-template-card-intro">
            <CardIntro
              title={title}
              text={text}
              imageRight={typeof rightSideImage === "string" ? rightSideImage : rightSideImage?.image}
              imageStyle={typeof rightSideImage === "string" ? {} : rightSideImage?.style}
            />
          </div>
          <ButtonGroups>
            {back !== null && <Button {...back} />}
            {next !== null && <Button {...next} />}
          </ButtonGroups>
        </div>
      </Body>
      <Actions showSound={Boolean(audio)} muted={muted} toggleMuted={toggleMuted} />
      {audio}
    </BG>
  );
};

export default IntroTemplate;
