import Config from "src/Constants/Config";

import setCurrentPage from "src/Utils/setCurrentPage";

import store from "src/Reducers/store";
import { useSelector } from "react-redux";

import { Animation, Audios } from "@yamildiego/presentation-kit";

const PageId = 311;
const PreviousPageId = PageId - 1;
const NextPageId = PageId + 1;

const DATA_JOURNEY_ONE = () => {
  const path = "/images/employee_onboarding/phone_11/phone_";
  const DATA_IMAGES = [
    { image: `${Config.BASE_URL}${path}0.png`, effect: "animate-opacity", imageStyle: { right: 0, top: 0, width: "67.5rem" } },
    { image: `${Config.BASE_URL}${path}1.png`, imageStyle: { right: 0, top: 0, width: "67.5rem" } },
    { image: `${Config.BASE_URL}${path}2.png`, imageStyle: { right: 0, top: 0, width: "67.5rem" } },
  ];

  const currentPage = useSelector(() => store.getState().appReducer.currentPage);
  const muted = useSelector(() => store.getState().appReducer.muted);
  const audioSrc = `${Config.BASE_URL}/audios/employee_onboarding/PRCX_18_Employee Onboarding - ${PageId}.mp3`;
  return {
    bgImage: `${Config.BASE_URL}/images/employee_onboarding/bg_11.png`,
    labels: [
      {
        icon: `${Config.BASE_URL}/images/icons/calendar.png`,
        text: "Day 31",
      },
      {
        icon: `${Config.BASE_URL}/images/icons/location.png`,
        text: "Office",
      },
      {
        icon: `${Config.BASE_URL}/images/icons/induction.png`,
        text: "Speed to Competency",
      },
    ],
    text: "As Emma advances in her role, she\nreceives intensive coaching, daily\ninsights, and compliant modules that\nensure continuous growth and\nmaintaining compliance.",
    back: { label: "Go Back", type: "back", startIcon: "arrow-left", onClick: () => setCurrentPage(PreviousPageId) },
    next: { label: "Continue", type: "next", endIcon: "arrow-right", onClick: () => setCurrentPage(NextPageId) },
    rightSideComponent: <Animation pageNumber={PageId} currentPage={currentPage} images={DATA_IMAGES} />,
    audio: <Audios muted={muted} pageNumber={PageId} currentPage={currentPage} audios={[{ src: audioSrc, timer: 0 }]} />,
  };
};

export default DATA_JOURNEY_ONE;
