import Icon from "src/Components/Icon";

import "./index.css";

const Label = (props: LabelProps) => {
  const { icon, text } = props;
  const { style = {} } = props;

  return (
    <div className="base-label" style={style}>
      <Icon style={styles.icon} icon={icon} />
      <div className="base-label-text">{text}</div>
    </div>
  );
};

const styles = {
  icon: {
    width: "1.25rem",
    height: "1.25rem",
  },
};

export default Label;
