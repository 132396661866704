import "./index.css";

import Container from "../Container";
import { useData } from "src/Components/DataContext";
import { useEffect, useState } from "react";

const Header = (props: HeaderProps) => {
  const { logoStyle = {}, headerText } = props;
  const { styleRightSide = {} } = props;
  const data = useData();

  const [dataHeader, setDataHeader] = useState<HeaderData>({});

  useEffect(() => {
    setDataHeader(data?.header ?? {});
  }, [data]);

  const handleClick = () => {
    if (dataHeader?.onClick) dataHeader.onClick();
  };

  const formatText = (text: string) => ({ __html: text.replace(/\n/g, "<br>") });

  return (
    <>
      <div className="base-header-bg"></div>
      <Container>
        <div className="base-header">
          {dataHeader?.logoImage && (
            <img
              onClick={handleClick}
              style={{ ...styles.logo, ...logoStyle, cursor: dataHeader?.onClick ? "pointer" : "default" }}
              src={dataHeader?.logoImage}
              alt="logo"
            />
          )}
          {headerText && (
            <div className="base-header-text-right" style={{ textAlign: "center" }} dangerouslySetInnerHTML={formatText(headerText)} />
          )}
          {!headerText && dataHeader?.textRightSide && (
            <div className="base-header-text-right" style={styleRightSide}>
              {dataHeader?.textRightSide ?? ""}
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

const styles = {
  logo: {
    width: "15.0625rem",
  },
};

export default Header;
