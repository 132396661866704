import { TemplateType } from "@yamildiego/presentation-kit";

import DATA_INTRO from "./01DATA_INTRO";
import DATA_INTRO_02 from "./02DATA_INTRO";

const DEMO_WORKFLOW = [
  { page: 0, type: "base-template" as TemplateType, dataTemplate: DATA_INTRO },
  { page: 1, type: "base-template" as TemplateType, dataTemplate: DATA_INTRO_02 },
];

export default DEMO_WORKFLOW;
