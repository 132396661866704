import "./index.css";

interface ButtonGroupsProps {
  style?: React.CSSProperties;
  children: React.ReactNode;
}
const ButtonGroups = (props: ButtonGroupsProps) => {
  const { style = {} } = props;

  return (
    <div className="base-button-groups" style={style}>
      {props.children}
    </div>
  );
};

export default ButtonGroups;
