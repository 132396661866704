import Config from "src/Constants/Config";

import setCurrentPage from "src/Utils/setCurrentPage";

import { Audios, Animation } from "@yamildiego/presentation-kit";

import store from "src/Reducers/store";
import { useSelector } from "react-redux";

const PageId = 230;
const PreviousPageId = PageId - 1;
const NextPageId = PageId + 1;

const DATA_CHAT = () => {
  const path = "/images/balance_rollover/chat_30/chat_";

  const DATA_IMAGES = [
    { image: `${Config.BASE_URL}${path}0.png`, effect: "animate-opacity", imageStyle: { position: "static", width: "100%" } },
    { image: `${Config.BASE_URL}${path}1.png`, effect: "animate-opacity", imageStyle: { position: "static", width: "100%" } },
    { image: `${Config.BASE_URL}${path}2.png`, effect: "animate-opacity", imageStyle: { position: "static", width: "100%" } },
    { image: `${Config.BASE_URL}${path}3.png`, effect: "animate-opacity", imageStyle: { position: "static", width: "100%" } },
    { image: `${Config.BASE_URL}${path}4.png`, effect: "animate-opacity", imageStyle: { position: "static", width: "100%" } },
  ];

  const pathAudios = `${Config.BASE_URL}/audios/balance_rollover/`;

  const AUDIOS = [
    { src: `${pathAudios}Balance - Scene 26 - Male 1 - v1.mp3`, timer: 100 },
    { src: `${pathAudios}Balance - Scene 26 - Emma 1 - v1.mp3`, timer: 5200 },
    { src: `${pathAudios}Balance - Scene 26 - Male 2 - v1.mp3`, timer: 12800 },
  ];

  const times = [600, 2500, 1400, 5800, 16000];

  const currentPage = useSelector(() => store.getState().appReducer.currentPage);
  const muted = useSelector(() => store.getState().appReducer.muted);

  return {
    bgImage: `${Config.BASE_URL}/images/balance_rollover/bg_29.png`,
    back: { type: "back", startIcon: "arrow-left", onClick: () => setCurrentPage(PreviousPageId) },
    next: { type: "next", endIcon: "arrow-right", onClick: () => setCurrentPage(NextPageId) },
    chatImages: <Animation times={times} pageNumber={PageId} currentPage={currentPage} images={DATA_IMAGES} />,
    audio: <Audios muted={muted} pageNumber={PageId} currentPage={currentPage} audios={AUDIOS} />,
  };
};

export default DATA_CHAT;
