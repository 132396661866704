import Config from "src/Constants/Config";

import setCurrentPage from "src/Utils/setCurrentPage";

import store from "src/Reducers/store";
import { useSelector } from "react-redux";

import { Audios, Animation } from "@yamildiego/presentation-kit";

const PageId = 108;
const PreviousPageId = PageId - 1;
const NextPageId = PageId + 1;

const DATA_JOURNEY_ONE = () => {
  const path = "/images/death_claim/win_08/Frame_";
  const DATA_IMAGES = [
    {
      image: `${Config.BASE_URL}${path}1.png`,
      effect: "animate-opacity",
      imageStyle: { position: "absolute", right: 0, top: 0, width: "67.5rem" },
    },
    {
      image: `${Config.BASE_URL}${path}2.png`,
      effect: "animate-opacity",
      imageStyle: { position: "absolute", right: 0, top: 0, width: "67.5rem" },
    },
    {
      image: `${Config.BASE_URL}${path}3.png`,
      effect: "animate-opacity",
      imageStyle: { position: "absolute", right: 0, top: 0, width: "67.5rem" },
    },
    {
      image: `${Config.BASE_URL}${path}4.png`,
      effect: "animate-opacity",
      imageStyle: { position: "absolute", right: 0, top: 0, width: "67.5rem" },
    },
    {
      image: `${Config.BASE_URL}${path}5.png`,
      effect: "animate-opacity",
      imageStyle: { position: "absolute", right: 0, top: 0, width: "67.5rem" },
    },
    {
      image: `${Config.BASE_URL}${path}6.png`,
      effect: "animate-opacity",
      imageStyle: { position: "absolute", right: 0, top: 0, width: "67.5rem" },
    },
    {
      image: `${Config.BASE_URL}${path}7.png`,
      effect: "animate-opacity",
      imageStyle: { position: "absolute", right: 0, top: 0, width: "67.5rem" },
    },
  ];

  const currentPage = useSelector(() => store.getState().appReducer.currentPage);
  const muted = useSelector(() => store.getState().appReducer.muted);
  const audioSrc = `${Config.BASE_URL}/audios/death_claim/PRCX_18_Death Claim - ${PageId}.mp3`;

  return {
    bgImage: `${Config.BASE_URL}/images/death_claim/bg_opacity_05.png`,
    labels: [
      {
        icon: `${Config.BASE_URL}/images/icons/clock.png`,
        text: "8:10 AM",
      },
      {
        icon: `${Config.BASE_URL}/images/icons/location.png`,
        text: "Probe CX Office",
      },
      {
        icon: `${Config.BASE_URL}/images/icons/genesys.png`,
        text: "Genesys",
        style: { backgroundColor: "#c3834e" },
      },
    ],
    text: "Sonya shares both her late husband's\ndetails and her personal information,\nwhich CRM validates.",
    back: { label: "Go Back", type: "back", startIcon: "arrow-left", onClick: () => setCurrentPage(PreviousPageId) },
    next: { label: "Continue", type: "next", endIcon: "arrow-right", onClick: () => setCurrentPage(NextPageId) },
    rightSideComponent: (
      <Animation times={[0, 600, 900, 900, 700, 2000]} pageNumber={PageId} currentPage={currentPage} images={DATA_IMAGES} />
    ),
    audio: <Audios muted={muted} pageNumber={PageId} currentPage={currentPage} audios={[{ src: audioSrc, timer: 0 }]} />,
  };
};

export default DATA_JOURNEY_ONE;
