import Template from "src/Components/Template";
import { useSpring, animated } from "@react-spring/web";

import { TemplateType, TemplateDataMap } from "src/Types/DataTemplate";

interface TemplateItem {
  page: number;
  type: TemplateType;
  dataTemplate: TemplateDataMap[TemplateType];
}

interface TemplatesAnimationProps {
  currentPage: number;
  muted: boolean;
  toggleMuted: () => void;
  headerText?: string;
  templates: TemplateItem[];
}

const TemplatesAnimation = (props: TemplatesAnimationProps) => {
  const { currentPage } = props;
  const { templates } = props;
  const { muted, toggleMuted } = props;

  return (
    <>
      {templates.map((template, index) => (
        <SingleAnimation key={index} currentPage={currentPage} page={template.page}>
          <Template
            muted={muted}
            toggleMuted={toggleMuted}
            headerText={props.headerText}
            type={template.type as TemplateType}
            dataTemplate={template.dataTemplate}
          />
        </SingleAnimation>
      ))}
    </>
  );
};

interface SingleAnimationProps {
  children: React.ReactNode;
  currentPage: number;
  page: number;
}

const SingleAnimation = (props: SingleAnimationProps) => {
  const { children, currentPage, page } = props;

  const animationShow = useSpring({
    opacity: currentPage === page ? 1 : 0,
    zIndex: currentPage === page ? 1 : 0,
    position: "absolute" as React.CSSProperties["position"],
    width: "100%",
    top: 0,
  });

  return <animated.div style={{ ...animationShow }}>{children}</animated.div>;
};

export default TemplatesAnimation;
