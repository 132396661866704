import { useEffect, useRef, useState } from "react";
import Image from "src/Components/Image";
import "./index.css";

import Audio from "../Audio";

type AudioType = { src: string; timer: number; muted: boolean };

interface AnimationProps {
  images: { image: string; imageStyle: React.CSSProperties | {}; effect?: "animate-up" | "animate-opacity" | "animate-phone" }[];
  pageNumber: number;
  currentPage: number;
  times?: number[];
  audios?: AudioType[];
  conversationStyle?: React.CSSProperties;
}

const Animation = ({ images, times, pageNumber, conversationStyle = {}, currentPage, audios = [] }: AnimationProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const bubbles = containerRef.current?.querySelectorAll(".base-animation-message-bubble") || [];

  const [adjustedTimes, setAdjustedTimes] = useState<number[]>([]);
  const [play, setPlay] = useState<boolean>(false);

  useEffect(() => {
    const adjustTimes = () => {
      if (!times || times.length < images.length) {
        const newTimes = times ? [...times] : [0];
        while (newTimes.length < images.length) newTimes.push(900);
        return newTimes;
      } else return times;
    };

    setAdjustedTimes(adjustTimes());
  }, [images, times]);

  console.log("adjustedTimes", currentPage);

  useEffect(() => {
    const play = pageNumber === currentPage;
    console.log("pageNumber", pageNumber);
    console.log("currentPage", currentPage);
    setPlay(play);
  }, [pageNumber, currentPage]);

  useEffect(() => {
    bubbles.forEach((bubble, index) => {
      bubble.classList.remove(images[index].effect ? (images[index].effect as string) : "animate-up");
    });

    if (pageNumber === currentPage) startEffect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, currentPage]);

  const startEffect = () => {
    bubbles.forEach((bubble, index) => {
      setTimeout(
        () => bubble.classList.add(images[index].effect ? (images[index].effect as string) : "animate-up"),
        adjustedTimes.slice(0, index + 1).reduce((sum, num) => sum + num, 0)
      );
    });

    if (containerRef.current) containerRef.current.scrollTop = containerRef.current.scrollHeight;
  };
  return (
    <div className="base-animation" style={conversationStyle} ref={containerRef}>
      <div style={{ position: "relative", overflow: "hidden", width: "100%", height: "100%" }}>
        {images.map((item, index) => (
          <Image
            className={"base-animation-message-bubble"}
            key={index}
            styleImage={{ ...styles.img, ...item.imageStyle }}
            imagePath={item.image}
          />
        ))}
      </div>

      {audios.map((audio, index) => (
        <Audio {...audio} key={index} play={play} />
      ))}
    </div>
  );
};

const styles = {
  img: {
    position: "absolute" as React.CSSProperties["position"],
    top: 0,
    right: 0,
  },
};

export default Animation;
