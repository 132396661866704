import "./index.css";

interface LabelGroupsProps {
  style?: React.CSSProperties;
  children: React.ReactNode;
}
const LabelGroups = (props: LabelGroupsProps) => {
  const { style = {} } = props;

  return (
    <div className="base-label-groups" style={style}>
      {props.children}
    </div>
  );
};

export default LabelGroups;
