import { TemplateType } from "@yamildiego/presentation-kit";

import DATA_INTRO from "./01DATA_INTRO";
import DATA_JOURNEY_ONE_02 from "./02DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_03 from "./03DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_04 from "./04DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_05 from "./05DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_06 from "./06DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_07 from "./07DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_08 from "./08DATA_JOURNEY_ONE";
import DATA_CHAT_09 from "./09DATA_CHAT";
import DATA_JOURNEY_ONE_10 from "./10DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_11 from "./11DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_12 from "./12DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_13 from "./13DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_14 from "./14DATA_JOURNEY_ONE";
import DATA_CHAT_15 from "./15DATA_CHAT";
import DATA_JOURNEY_ONE_16 from "./16DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_17 from "./17DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_18 from "./18DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_185 from "./18.5DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_19 from "./19DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_20 from "./20DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_21 from "./21DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_22 from "./22DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_23 from "./23DATA_JOURNEY_ONE";

const DEMO_WORKFLOW = [
  { page: 101, type: "intro-template" as TemplateType, dataTemplate: DATA_INTRO },
  { page: 102, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_02 },
  { page: 103, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_03 },
  { page: 104, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_04 },
  { page: 105, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_05 },
  { page: 106, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_06 },
  { page: 107, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_07 },
  { page: 108, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_08 },
  { page: 109, type: "chat-template" as TemplateType, dataTemplate: DATA_CHAT_09 },
  { page: 110, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_10 },
  { page: 111, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_11 },
  { page: 112, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_12 },
  { page: 113, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_13 },
  { page: 114, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_14 },
  { page: 115, type: "chat-template" as TemplateType, dataTemplate: DATA_CHAT_15 },
  { page: 116, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_16 },
  { page: 117, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_17 },
  { page: 118, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_18 },
  { page: 118.5, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_185 },
  { page: 119, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_19 },
  { page: 120, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_20 },
  { page: 121, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_21 },
  { page: 122, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_22 },
  { page: 123, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_23 },
];

export default DEMO_WORKFLOW;
