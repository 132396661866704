import { useEffect } from "react";
import { throttle } from "lodash";

const useCustomEffect = () => {
  const baseFontSize = 16;
  const screenWidthReference = 1920;
  const screenHeightReference = 1080;
  const minWidth = 667;
  const maxWidth = 1920;
  const minHeight = 375;

  useEffect(() => {
    const updateBaseFontSize = () => {
      const currentScreenWidth = Math.min(Math.max(window.innerWidth, minWidth), maxWidth);
      const currentScreenHeight = Math.max(window.innerHeight, minHeight);
      const scaleFactorWidth = currentScreenWidth / screenWidthReference;
      const scaleFactorHeight = currentScreenHeight / screenHeightReference;
      const scaleFactor = Math.min(scaleFactorWidth, scaleFactorHeight);
      const newFontSize = baseFontSize * scaleFactor;
      document.documentElement.style.fontSize = `${newFontSize}px`;
    };

    const throttledUpdateBaseFontSize = throttle(updateBaseFontSize, 100);

    updateBaseFontSize();
    window.addEventListener("resize", throttledUpdateBaseFontSize);

    return () => {
      window.removeEventListener("resize", throttledUpdateBaseFontSize);
    };
  }, []);
};

export default useCustomEffect;
