import { useEffect, useState } from "react";

import Audio from "../Audio";

type AudioType = { src: string; timer: number };

interface AnimationProps {
  pageNumber: number;
  currentPage: number;
  audios?: AudioType[];
  muted?: boolean;
}

const Audios = ({ muted, pageNumber, currentPage, audios = [] }: AnimationProps) => {
  const [play, setPlay] = useState<boolean>(false);

  useEffect(() => {
    const play = pageNumber === currentPage;
    setPlay(play);
  }, [pageNumber, currentPage]);

  return (
    <>
      {audios.map((audio, index) => (
        <Audio muted={Boolean(muted)} {...audio} key={index} play={play} />
      ))}
    </>
  );
};

export default Audios;
