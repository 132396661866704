import { useState, useEffect } from "react";

import "./index.css";

interface ISound {
  show: boolean;
  muted: boolean;
  toggleMuted: () => void;
}

const Sound = (props: ISound) => {
  const [show, setShow] = useState<boolean>(false);
  const [localMuted, setLocalMuted] = useState<boolean>(false);

  const toggleMuted = () => props.toggleMuted();

  useEffect(() => {
    setShow(Boolean(props.show));
  }, []);

  useEffect(() => {
    console.log("{}props.muted", props.muted);
    setLocalMuted(Boolean(props.muted));
  }, [props.muted]);

  return (
    <>
      {show && (
        <div className="base-sound" onClick={toggleMuted}>
          {localMuted ? (
            <img src={require(`./Assets/Icons/white/sound-off.png`)} alt="" style={{ padding: "1rem", width: "3rem" }} />
          ) : (
            <img src={require(`./Assets/Icons/white/sound-on.png`)} alt="" style={{ padding: "1rem", width: "3rem" }} />
          )}
        </div>
      )}
    </>
  );
};

export default Sound;
