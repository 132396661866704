import Config from "src/Constants/Config";

import setCurrentPage from "src/Utils/setCurrentPage";

import store from "src/Reducers/store";
import { useSelector } from "react-redux";

import { Audios } from "@yamildiego/presentation-kit";

const PageId = 234;
const PreviousPageId = PageId - 1;
const NextPageId = 1;

const DATA_JOURNEY_ONE = () => {
  const currentPage = useSelector(() => store.getState().appReducer.currentPage);
  const muted = useSelector(() => store.getState().appReducer.muted);
  const audioSrc = `${Config.BASE_URL}/audios/balance_rollover/PRCX_18_Balance - ${PageId}.mp3`;

  return {
    bgImage: `${Config.BASE_URL}/images/balance_rollover/bg_34.png`,
    labels: [
      {
        icon: `${Config.BASE_URL}/images/icons/clock.png`,
        text: "6:20 PM",
      },
      {
        icon: `${Config.BASE_URL}/images/icons/location.png`,
        text: "James' Home",
      },
      {
        icon: `${Config.BASE_URL}/images/icons/assist.png`,
        text: "Agent Assist",
        style: { backgroundColor: "#c3834e" },
      },
    ],
    text: "The CRM identifies that James is\ninterested in changing his portfolio,\nand through omnichannel\ncommunications, it shares relevant\narticles, blogs, and more.",
    rightSideImage: `${Config.BASE_URL}/images/balance_rollover/phone_34.png`,
    back: { label: "Go Back", type: "back", startIcon: "arrow-left", onClick: () => setCurrentPage(PreviousPageId) },
    next: { label: "Exit", type: "next", endIcon: "arrow-right", onClick: () => setCurrentPage(NextPageId) },
    audio: <Audios muted={muted} pageNumber={PageId} currentPage={currentPage} audios={[{ src: audioSrc, timer: 0 }]} />,
  };
};

export default DATA_JOURNEY_ONE;
