import BG from "src/Components/BG";
import Body from "src/Components/Body";
import Button from "src/Components/Button";
import ButtonGroups from "src/Components/ButtonGroups";
import Actions from "src/Components/Actions";

import "./index.css";

interface ChatTemplateProps {
  muted: boolean;
  toggleMuted: () => void;
  data: DataChatTemplate;
}

const ChatTemplate = (props: ChatTemplateProps) => {
  const { bgImage, leftSideImage, rightSideImage, chatImages, next, back, audio } = props.data;
  const { toggleMuted } = props;

  const templateType = "chat-template";

  return (
    <BG bgImage={bgImage}>
      <Body>
        <div className="base-chat-template">
          {leftSideImage && (
            <div style={leftSideImage.style}>
              <img style={{ position: "relative", width: "100%", zIndex: 0 }} src={leftSideImage.image} alt="" />
            </div>
          )}
          <div style={{ flex: 1, position: "absolute", width: "100%", height: "100%", zIndex: 1000 }}>
            {chatImages}
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                height: "100%",
                justifyContent: "center",
                flex: "1",
                position: "relative",
                zIndex: 100,
              }}
            >
              <ButtonGroups style={{ marginBottom: "5.5625rem" }}>
                {back !== null && <Button {...back} />}
                {next !== null && <Button {...next} />}
              </ButtonGroups>
            </div>
          </div>
          {rightSideImage && (
            <div style={rightSideImage.style}>
              <img style={{ position: "relative", width: "100%", zIndex: 0 }} src={rightSideImage.image} alt="" />
            </div>
          )}
        </div>
      </Body>
      <Actions showSound={Boolean(audio)} muted={props.muted} toggleMuted={toggleMuted} />
      {audio}
    </BG>
  );
};

export default ChatTemplate;
