import ReactDOM from "react-dom/client";
import App from "./App";

import { Provider } from "react-redux";

import store from "./Reducers/store";
import { ThemeProvider } from "@yamildiego/presentation-kit";

import "./Assets/css/index.css";
import "@yamildiego/presentation-kit/dist/Assets/Fonts/index.css";

import setCurrentPage from "src/Utils/setCurrentPage";
import Loading from "./Component/Loading";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const data = {
  Button: {
    base: {
      fontSize: "1.5rem",
      background: "yellow",
    },
    next: {
      background: "white",
    },
    back: {
      background: "transparent",
      border: "1px solid white",
      color: "white",
    },
  },
};

const globalData = {
  header: {
    logoImage: "./images/logo.png",
    textRightSide: "",
    onClick: () => setCurrentPage(0),
  },
};

root.render(
  <Provider store={store}>
    <Loading />
    <ThemeProvider themeStyles={data} globalData={globalData}>
      <App />
    </ThemeProvider>
  </Provider>
);
