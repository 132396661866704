interface BGProps {
  bgImage?: string;
  children: React.ReactNode;
}

const BG = (props: BGProps) => {
  const { bgImage } = props;

  return (
    <div
      style={{
        width: "-webkit-fill-available",
        height: "67.5rem",
        minWidth: "667px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...(bgImage
          ? {
              backgroundImage: `url(${bgImage})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }
          : {}),
      }}
    >
      <div style={styles.container}>{props.children}</div>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: "1920px",
    minWidth: "667px",
    overflow: "auto",
    height: "-webkit-fill-available",
    width: "100%",
    display: "flex",
    flexDirection: "column" as React.CSSProperties["flexDirection"],
  },
};

export default BG;
