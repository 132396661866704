import { TemplatesAnimation, useResizeBase, ScreenOrientation } from "@yamildiego/presentation-kit";

import DEATH_CLAIM from "src/Data/DEATH_CLAIM/DEMO_WORKFLOW";
import BALANCE_ROLLOVER from "src/Data/BALANCE_ROLLOVER/DEMO_WORKFLOW";
import EMPLOYEE_ONBOARDING from "src/Data/EMPLOYEE_ONBOARDING/DEMO_WORKFLOW";
import INTRO from "src/Data/INTRO/DEMO_WORKFLOW";

import connectWithDispatch from "src/Hooks/connectWithDispatch";
import * as appActions from "src/Actions/appActions";

interface AppProps {
  currentPage: number;
  muted: boolean;
  setMuted: (value: boolean) => void;
}

const App = (props: AppProps) => {
  useResizeBase();
  const { currentPage, setMuted, muted } = props;

  const toggleMuted = () => {
    setMuted(!muted);
  };

  return (
    <>
      <div style={{ position: "absolute", top: 0, left: 0, color: "red", zIndex: 100 }}>{currentPage}</div>
      <ScreenOrientation>
        <TemplatesAnimation headerText={`SuperAnnuation\nAGENT & Member Experience`} currentPage={currentPage} templates={INTRO} />
        <TemplatesAnimation
          muted={muted}
          toggleMuted={toggleMuted}
          headerText="Member Experience"
          currentPage={currentPage}
          templates={BALANCE_ROLLOVER}
        />
        <TemplatesAnimation
          muted={muted}
          toggleMuted={toggleMuted}
          headerText="Member Experience"
          currentPage={currentPage}
          templates={DEATH_CLAIM}
        />
        <TemplatesAnimation
          muted={muted}
          toggleMuted={toggleMuted}
          headerText="Agent Experience"
          currentPage={currentPage}
          templates={EMPLOYEE_ONBOARDING}
        />
      </ScreenOrientation>
    </>
  );
};

const mapStateToProps = (state: StateType) => {
  return {
    currentPage: state.appReducer.currentPage,
    muted: state.appReducer.muted,
  };
};

const mapDispatchToProps = {
  setMuted: appActions.setMuted,
};

export default connectWithDispatch(App, mapStateToProps, mapDispatchToProps);
