import { useEffect, useRef } from "react";

interface IAudio {
  play?: boolean;
  src?: string;
  timer?: number;
  muted: boolean;
}

const Audio = ({ muted, play = false, src, timer = 1000 }: IAudio) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const audioElement = audioRef.current;
    if (src !== null && audioElement) {
      if (play) {
        timerRef.current = setTimeout(() => {
          audioElement.currentTime = 0;
          audioElement.play();
        }, timer);
      } else {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
          timerRef.current = null;
        }
        audioElement.pause();
        audioElement.currentTime = 0;
      }
    }

    // Limpiar en desmontaje
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [play, src, timer]);

  useEffect(() => {
    const audioElement = audioRef.current;
    if (audioElement) {
      audioElement.muted = muted;
    }
  }, [muted]);

  return <>{src && <audio ref={audioRef} src={src} />}</>;
};

export default Audio;
