import Config from "src/Constants/Config";

import setCurrentPage from "src/Utils/setCurrentPage";

import store from "src/Reducers/store";
import { useSelector } from "react-redux";

import { Audios } from "@yamildiego/presentation-kit";

const PageId = 101;
const PreviousPageId = 1;
const NextPageId = PageId + 1;

const DATA_INTRO = () => {
  const currentPage = useSelector(() => store.getState().appReducer.currentPage);
  const muted = useSelector(() => store.getState().appReducer.muted);
  const audioSrc = `${Config.BASE_URL}/audios/death_claim/PRCX_18_Death Claim - ${PageId}.mp3`;

  return {
    bgImage: `${Config.BASE_URL}/images/death_claim/bg.png`,
    title: "Introduction",
    text: `Sonya strolls along a familiar path in the\nRoyal Botanical Garden, one she and her late\nhusband frequented often, offering a serene\nview of the harbour.`,
    rightSideImage: { image: `${Config.BASE_URL}/images/death_claim/OlderWoman.png`, style: { top: "-7.8125rem", width: "25.75rem" } },
    back: { label: "Go Back", type: "back", startIcon: "arrow-left", onClick: () => setCurrentPage(PreviousPageId) },
    next: { label: "Continue", type: "next", endIcon: "arrow-right", onClick: () => setCurrentPage(NextPageId) },
    audio: <Audios muted={muted} pageNumber={PageId} currentPage={currentPage} audios={[{ src: audioSrc, timer: 0 }]} />,
  };
};

export default DATA_INTRO;
