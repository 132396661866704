import { useEffect, useState } from "react";

interface IconProps {
  icon: string;
  style?: React.CSSProperties;
  color?: string;
}

const Icon = (props: IconProps) => {
  const { style = {}, color = "black" } = props;

  const [icon, setIcon] = useState<string>("");

  const colorPath = color !== null ? `${color}/` : "";

  const isAnUrl = (text: string) => /^(http:\/\/|https:\/\/)/.test(text);

  useEffect(() => {
    if (isAnUrl(props.icon)) setIcon(props.icon);
    else setIcon(require(`./Assets/Icons/${colorPath}${props.icon}.png`));
  }, [props.icon]);

  return <img style={{ width: "2.625rem", height: "2.625rem", ...style }} src={icon} alt="" />;
};

export default Icon;
