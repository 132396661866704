import "./index.css";

const Text = (props: TextProps) => {
  const { text, style = {}, templateType } = props;

  const formatText = (text: string) => ({ __html: text.replace(/\n/g, "<br>") });

  return <div className={`base-text base-text-${templateType}`} style={style} dangerouslySetInnerHTML={formatText(text)} />;
};

export default Text;
