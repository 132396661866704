interface ContainerProps {
  children: React.ReactNode;
}

const Container = (props: ContainerProps) => {
  return <div style={styles.container}>{props.children}</div>;
};

const styles = {
  container: {
    justifyContent: "center",
    paddingLeft: "6.125rem",
    paddingRight: "6.5625rem",
  },
};

export default Container;
