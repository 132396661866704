import Config from "src/Constants/Config";

import setCurrentPage from "src/Utils/setCurrentPage";

import store from "src/Reducers/store";
import { useSelector } from "react-redux";

import { Audios } from "@yamildiego/presentation-kit";

const PageId = 312;
const PreviousPageId = PageId - 1;
const NextPageId = PageId + 1;

const DATA_JOURNEY_ONE = () => {
  const currentPage = useSelector(() => store.getState().appReducer.currentPage);
  const muted = useSelector(() => store.getState().appReducer.muted);
  const audioSrc = `${Config.BASE_URL}/audios/employee_onboarding/PRCX_18_Employee Onboarding - ${PageId}.mp3`;
  return {
    bgImage: `${Config.BASE_URL}/images/employee_onboarding/bg_5.png`,
    labels: [
      {
        icon: `${Config.BASE_URL}/images/icons/calendar.png`,
        text: "Day 240",
      },
      {
        icon: `${Config.BASE_URL}/images/icons/location.png`,
        text: "Office",
      },
    ],
    text: "After 8 months, Probe CX\nrecognises Emma's impressive\ngrowth and outstanding\nperformance, marking her a\ncandidate for a leadership program.",
    rightSideImage: `${Config.BASE_URL}/images/employee_onboarding/win_12.png`,
    back: { label: "Go Back", type: "back", startIcon: "arrow-left", onClick: () => setCurrentPage(PreviousPageId) },
    next: { label: "Continue", type: "next", endIcon: "arrow-right", onClick: () => setCurrentPage(NextPageId) },
    audio: <Audios muted={muted} pageNumber={PageId} currentPage={currentPage} audios={[{ src: audioSrc, timer: 0 }]} />,
  };
};

export default DATA_JOURNEY_ONE;
