import setCurrentPage from "src/Utils/setCurrentPage";

const charactersData = [
  {
    id: 2,
    image: "./images/intro/character_2.png",
    label: "Balance and Rollover",
    onClick: () => setCurrentPage(201),
  },
  {
    id: 1,
    image: "./images/intro/character_1.png",
    label: "Death Claim",
    onClick: () => setCurrentPage(101),
  },
  {
    id: 3,
    image: "./images/intro/character_3.png",
    label: "Employee Onboarding",
    onClick: () => setCurrentPage(301),
  },
];

const Characters = () => {
  return (
    <div style={styles.container}>
      <div style={styles.box}>
        <div style={styles.title}>Choose your experience</div>
        <div style={styles.cards}>
          {charactersData.map((item, index) => (
            <>
              <div key={item.id} style={styles.item} onClick={item.onClick}>
                <img key={item.id} style={styles.img} src={item.image} alt={item.label} onClick={item.onClick} />
                <div style={styles.label}>
                  {item.label}
                  <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41" fill="none">
                    <path
                      d="M24.8872 10.2238L35.1007 20.4374L24.8872 30.6509"
                      stroke="#111111"
                      strokeWidth="1.923"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6.49609 20.4374H34.8146"
                      stroke="#111111"
                      strokeWidth="1.923"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
              {index === 1 && <div style={styles.divider}></div>}
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    width: "100%",
    display: "flex" as React.CSSProperties["display"],
    flexDirection: "column" as React.CSSProperties["flexDirection"],
    justifyContent: "center" as React.CSSProperties["justifyContent"],
    alignItems: "center" as React.CSSProperties["alignItems"],
  },
  box: {
    display: "inline-flex",
    padding: "3.125rem 6.25rem",
    flexDirection: "column" as React.CSSProperties["flexDirection"],
    alignItems: "center",
    borderRadius: "1.25rem",
    background: "rgba(30, 30, 30, 0.90)",
    boxShadow: "0px 0px 24px 0px rgba(0, 0, 0, 0.25)",
  },
  title: {
    color: "#FFF",
    textAlign: "center" as React.CSSProperties["textAlign"],
    fontFamily: "Arial",
    fontSize: "3.375rem",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "100%",
    marginBottom: "1.5rem",
  },
  cards: {
    display: "flex" as React.CSSProperties["display"],
    flexDirection: "row" as React.CSSProperties["flexDirection"],
    justifyContent: "space-between" as React.CSSProperties["justifyContent"],
    alignItems: "center" as React.CSSProperties["alignItems"],
    gap: "5.99rem",
  },
  item: {
    width: "23.01588rem",
    flexShrink: "0",
    cursor: "pointer",
  },
  img: {
    width: "100%",
    display: "block",
  },
  label: {
    background: "rgb(255, 255, 255)",
    borderRadius: "0px 0px 1.20188rem 1.20188rem",
    color: "var(--Dark, #111)",
    fontFamily: "Arial",
    fontSize: "1.44225rem",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "100%",
    display: "flex",
    padding: "0.72113rem 1.262rem 0.72113rem 1.68263rem",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "0px 5.482px 58.936px 0px rgba(255, 255, 255, 0.30)",
    border: "1px solid #444",
  },
  divider: {
    height: "25rem",
    width: "1px",
    backgroundColor: "#ddd",
    alignSelf: "center",
    margin: "0 1rem",
  },
};

export default Characters;
