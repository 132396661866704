import * as Types from "src/Constants/Types";

const initialState: AppReducer = {
  currentPage: 0,
  previousPage: 1,
  muted: false,
};

type SetPageAction = { type: typeof Types.SET_CURRENT_PAGE; value: number };
type SetPreviousPageAction = { type: typeof Types.SET_PREVIOUS_PAGE; value: number };
type SetMutedAction = { type: typeof Types.SET_MUTED; value: boolean };

type AppReducerAction = SetPageAction | SetPreviousPageAction | SetMutedAction;

export default function appReducer(state = initialState, action: AppReducerAction) {
  switch (action.type) {
    case Types.SET_CURRENT_PAGE:
      return { ...state, currentPage: action.value };
    case Types.SET_PREVIOUS_PAGE:
      return { ...state, previousPage: action.value };
    case Types.SET_MUTED:
      return { ...state, muted: action.value };
    default:
      return state;
  }
}
