import ChatTemplate from "./ChatTemplate";
import BaseTemplate from "./BaseTemplate";
import IntroTemplate from "./IntroTemplate";
import JourneyOneTemplate from "./JourneyOneTemplate";

import { TemplateProps, TemplateType } from "src/Types/DataTemplate";

const Template: React.FC<TemplateProps<TemplateType>> = ({ type, dataTemplate, headerText, muted, toggleMuted }) => {
  const data = typeof dataTemplate === "function" ? dataTemplate() : dataTemplate;

  const templates: { [key: string]: any } = {
    "base-template": <BaseTemplate headerText={headerText} data={data as DataBaseTemplate} />,
    "intro-template": (
      <IntroTemplate
        muted={muted ? muted : false}
        toggleMuted={toggleMuted ? toggleMuted : () => console.log("toggleMuted undefined")}
        headerText={headerText}
        data={data as DataIntroTemplate}
      />
    ),
    "journey-one-template": (
      <JourneyOneTemplate
        muted={muted ? muted : false}
        toggleMuted={toggleMuted ? toggleMuted : () => console.log("toggleMuted undefined")}
        headerText={headerText}
        data={data as DataJourneyOneTemplate}
      />
    ),
    "chat-template": (
      <ChatTemplate
        muted={muted ? muted : false}
        toggleMuted={toggleMuted ? toggleMuted : () => console.log("toggleMuted undefined")}
        data={data as DataChatTemplate}
      />
    ),
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", background: "#000" }}>
      <div style={{ position: "relative", width: "-webkit-fill-available" }}>{templates[type]}</div>
    </div>
  );
};

export default Template;
