import { TemplateType } from "@yamildiego/presentation-kit";

import DATA_INTRO from "./01DATA_INTRO";
import DATA_INTRO_02 from "./02DATA_INTRO";
import DATA_JOURNEY_ONE_03 from "./03DATA_JOURNEY_ONE";
import DATA_CHAT_04 from "./04DATA_CHAT";
import DATA_JOURNEY_ONE_05 from "./05DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_06 from "./06DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_07 from "./07DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_08 from "./08DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_09 from "./09DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_10 from "./10DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_11 from "./11DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_12 from "./12DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_13 from "./13DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_15 from "./15DATA_JOURNEY_ONE";
import DATA_JOURNEY_ONE_16 from "./16DATA_JOURNEY_ONE";

const DEMO_WORKFLOW = [
  { page: 301, type: "intro-template" as TemplateType, dataTemplate: DATA_INTRO },
  { page: 302, type: "intro-template" as TemplateType, dataTemplate: DATA_INTRO_02 },
  { page: 303, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_03 },
  { page: 304, type: "chat-template" as TemplateType, dataTemplate: DATA_CHAT_04 },
  { page: 305, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_05 },
  { page: 306, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_06 },
  { page: 307, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_07 },
  { page: 308, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_08 },
  { page: 309, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_09 },
  { page: 310, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_10 },
  { page: 311, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_11 },
  { page: 312, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_12 },
  { page: 313, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_13 },
  { page: 315, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_15 },
  { page: 316, type: "journey-one-template" as TemplateType, dataTemplate: DATA_JOURNEY_ONE_16 },
];

export default DEMO_WORKFLOW;
